import { db, firebase } from '@/config/firebase.config.js';

const bookmarksCollection = db.collection('bookmarks');

export const getSongBookmark = (params) => {
  console.log('in get song bookmark', params);
  const { user_id, song_id } = params;
  return bookmarksCollection
    .where('user_id', '==', user_id)
    .where('song_id', '==', song_id)
    .limit(1)
    .get();
};

export const getUserSongBookmarks = (user_id, last_doc) => {
  console.log('in get user bookmarks', user_id, last_doc);
  let ref = bookmarksCollection
    .where('user_id', '==', user_id)
    .orderBy('created_at', 'desc');

  if (last_doc) {
    ref = ref.startAfter(last_doc);
  }

  return ref.limit(20).get();
};

export const addSongBookmark = async (params) => {
  const { user_id, song_id, title } = params;
  const doc = bookmarksCollection.doc();
  const id = doc.id;
  const payload = {
    id,
    user_id,
    song_id,
    title,
    created_at: firebase.firestore.Timestamp.now(),
  };
  await doc.set(payload);
  return payload;
};

export const deleteSongBookmark = (id) => {
  return bookmarksCollection.doc(id).delete();
};
