<template>
  <div>
    <template v-if="getBookmarksApiStatus === API_STATUS.PENDING">
      Checking bookmark...
    </template>

    <template v-else-if="getBookmarksApiStatus === API_STATUS.SUCCESS">
      <a
        class="bookmark__link"
        :disabled="isDeletingBookmark || isAddingBookmark"
        @click.prevent="onBookmarkLinkClick"
      >
        <img
          class="bookmark__icon"
          :src="bookmarkIcon"
          alt="Bookmark Outlined Icon"
        />
        <span class="bookmark__link--text">
          {{ bookmarkLinkText }}
        </span>
      </a>
    </template>
  </div>
</template>

<script>
import {
  getSongBookmark,
  deleteSongBookmark,
  addSongBookmark,
} from "@/api/bookmarkApi.js";
import { API_STATUS } from "@/constants/apiStatus.js";
import { userComputed } from "@/services/userService.js";
import AddBookmarkIcon from "@/assets/img/bookmark_outlined.svg";
import DeleteBookmarkIcon from "@/assets/img/rm_bookmark.svg";
const { IDLE, PENDING, SUCCESS, ERROR } = API_STATUS;

export default {
  name: "SongBookmark",

  props: {
    song_id: {
      type: [String, Number],
      required: true,
    },
    song_title: {
      type: [String],
      required: true,
    },
  },
  data() {
    return {
      bookmark: null,
      getBookmarksApiStatus: IDLE,
      addBookmarkApiStatus: IDLE,
      deleteBookmarkApiStatus: IDLE,
    };
  },
  computed: {
    API_STATUS() {
      return API_STATUS;
    },
    ...userComputed,
    bookmarkLinkText() {
      return this.bookmark ? this.deleteBookmarkText : this.addBookmarkText;
    },
    deleteBookmarkText() {
      return this.isDeletingBookmark
        ? "Removing bookmark..."
        : "Delete bookmark";
    },
    addBookmarkText() {
      return this.isAddingBookmark ? "Adding bookmark..." : "Add Bookmark";
    },
    isDeletingBookmark() {
      return this.deleteBookmarkApiStatus === PENDING;
    },
    isAddingBookmark() {
      return this.addBookmarkApiStatus === PENDING;
    },
    bookmarkIcon() {
      return this.bookmark ? DeleteBookmarkIcon : AddBookmarkIcon;
    },
  },
  watch: {
    user: {
      handler(user) {
        if (!user) return;
        console.log("user?", user);
        this.getBookmark({
          user_id: user.uid,
          song_id: this.song_id,
        });
      },
      immediate: true,
    },
  },
  methods: {
    onBookmarkLinkClick() {
      this.bookmark ? this.deleteBookmark() : this.addBookmark();
    },
    async getBookmark(params) {
      try {
        this.getBookmarksApiStatus = PENDING;
        const response = await getSongBookmark(params);
        console.log("response", response);
        this.getBookmarksApiStatus = SUCCESS;
        if (response.empty) return;

        const [doc] = response.docs;
        this.bookmark = doc.data();
      } catch (error) {
        console.error(error);
        this.getBookmarksApiStatus = ERROR;
      }
    },
    async addBookmark() {
      try {
        if (this.isAddingBookmark) return;
        this.addBookmarkApiStatus = PENDING;
        const bookmark = await addSongBookmark({
          user_id: this.user.uid,
          song_id: this.song_id,
          title: this.song_title,
        });
        console.log("add response", bookmark);
        this.bookmark = bookmark;
        this.addBookmarkApiStatus = SUCCESS;
      } catch (error) {
        console.error(error);
        this.addBookmarkApiStatus = ERROR;
      }
    },
    async deleteBookmark() {
      try {
        if (this.isDeletingBookmark) return;
        this.deleteBookmarkApiStatus = PENDING;
        await deleteSongBookmark(this.bookmark.id);
        this.bookmark = null;
        this.deleteBookmarkApiStatus = SUCCESS;
      } catch (error) {
        console.error(error);
        this.deleteBookmarkApiStatus = ERROR;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bookmark__link {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  opacity: 0.69;
  transition: 0.25s opacity;
  &:hover {
    opacity: 1;
  }
}

.bookmark__icon {
  margin-right: 0.75rem;
}
.bookmark__link--text {
  display: none;
  @include tablet {
    display: inline-block;
  }
}
</style>
