import Vue from 'vue';

const initialState = {
  user: null,
};

const state = Vue.observable(initialState);

export const setUser = (user) => (state.user = user);

export const userComputed = {
  user: {
    get() {
      return state.user;
    },
  },
};

export const updateUser = (data) => {
  state.user = {
    ...state.user,
    ...data,
  };
};
